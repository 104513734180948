import React, { useState, useEffect, useRef } from 'react';
import './CandidateCard.css';
import AnalyzeCandidateData from './AnalyzeCandidateData';
import linkedinIcon from './linkedin-icon.png';
import emailIcon from './email-icon.png';
import phoneIcon from './phone-icon.png';
import compensationLogo from './Compensation-Logo.png';
import educationLogo from './Education-Logo.png';
import languagesLogo from './Languages-Logo.png';
import notesLogo from './Notes-Logo.png';
import documentsLogo from './Documents-Logo.png';
import feedbackLogo from './feedback-icon.svg';
import addfeedbackLogo from './add-feedback.svg';
import scorecardIcon from './scorecard-icon.svg';
// Inside your CandidateCard component


const CandidateCard = ({ candidateData }) => {
	
 const [isScorecardModalOpen, setIsScorecardModalOpen] = useState(false);		
 const [isModalOpen, setIsModalOpen] = useState(false);
 const [isLoadingFeedback, setIsLoadingFeedback] = useState(false);


  const { 
	name, location, compensation, compensationNum, currentCompany, currentPosition,
    email, phone, context,language1, language2, language3, education1, education2, education3,score, notes, profileImage, url,  files,  status, tags
  } = candidateData;
  
  const renderMultiSelect = (items) => items.map((item, index) => (
	<span key={index} className={`badge ${item.color}`}>{item.name}</span>
  ));
  console.log("Candidate Data:", candidateData);
  const [feedbackText, setFeedbackText] = useState('');
  const [feedbackEntries, setFeedbackEntries] = useState([]);
  
 const formatRichText = (richTextArray) => {
   return richTextArray.flatMap((textObj, index) => {
	 const { text, annotations, plain_text, href } = textObj;
 
	 // Splitting text content by newline characters
	 let splitContent = plain_text.split('\n');
 
	 return splitContent.map((content, contentIndex) => {
	   let formattedText = <span key={`${index}-${contentIndex}`}>{content}</span>;
 
	   // Apply annotations
	   if (annotations) {
		 if (annotations.bold) {
		   formattedText = <strong key={`${index}-${contentIndex}`}>{formattedText}</strong>;
		 }
		 if (annotations.italic) {
		   formattedText = <em key={`${index}-${contentIndex}`}>{formattedText}</em>;
		 }
		 if (annotations.strikethrough) {
		   formattedText = <del key={`${index}-${contentIndex}`}>{formattedText}</del>;
		 }
		 if (annotations.underline) {
		   formattedText = <u key={`${index}-${contentIndex}`}>{formattedText}</u>;
		 }
		 if (annotations.code) {
		   formattedText = <code key={`${index}-${contentIndex}`}>{formattedText}</code>;
		 }
		 // Handle color if needed
		 if (annotations.color !== 'default') {
		   formattedText = <span key={`${index}-${contentIndex}`} style={{ color: annotations.color }}>{formattedText}</span>;
		 }
	   }
 
	   // If there's a hyperlink, wrap the formattedText in an anchor tag
	   if (href) {
		 formattedText = <a href={href} key={`${index}-${contentIndex}`} target="_blank" rel="noopener noreferrer">{formattedText}</a>;
	   }
 
	   // Return formatted text with a line break if it's not the last segment
	   return contentIndex < splitContent.length - 1
		 ? [formattedText, <br key={`br-${index}-${contentIndex}`} />]
		 : formattedText;
	 });
   });
 };
 


const ScorecardModal = ({ isOpen, onClose }) => {
	if (!isOpen) return null;
  
	const handleOutsideClick = (e) => {
	  if (e.target === e.currentTarget) {
		onClose();
	  }
	};
  
	return (
	  <div className="scorecard-modal-overlay" onClick={handleOutsideClick}>
		<div className="scorecard-modal-content" onClick={(e) => e.stopPropagation()} style={{ width: '80%', height: '80%' }}>
		  <div className="scorecard-modal-header">
			<h2>Scorecard</h2>
			<span className="scorecard-modal-close-button" onClick={onClose}>&times;</span>
		  </div>
		  <div className="scorecard-modal-body" style={{ width: '100%', height: '100%' }}>
			<iframe
			  style={{ width: '95%', height: '100%' }}
			  frameBorder="0"
			  scrolling="no"
			  src="https://docs.google.com/spreadsheets/d/11iOLCnukRQD0KQl-PYkIHNA14O8G8XRXQxoeW5yGgoA/edit?usp=sharing&amp;rm=minimal&amp;single=true&amp;"
			  title="Scorecard"
			></iframe>
		  </div>
		</div>
	  </div>
	);
  };






 // 
 // const ScorecardModal = ({ isOpen, onClose }) => {
 //   const [iframeSrc, setIframeSrc] = useState('');
 // 
 //   useEffect(() => {
	//  console.log('Effect running due to isOpen change:', isOpen);
	//  if (isOpen) {
	//    console.log('Setting iframe source because modal is open');
	//    setIframeSrc("https://pearsonham.sharepoint.com/sites/PearsonGroup/_layouts/15/Doc.aspx?sourcedoc={99653845-f667-4476-a886-0ffbe1aee855}&action=embedview&AllowTyping=True&wdHideGridlines=True&wdHideHeaders=True&wdDownloadButton=True&wdInConfigurator=True&wdInConfigurator=True");
	//  } else {
	//    console.log('Clearing iframe source because modal is closed');
	//    setIframeSrc(''); // Optionally clear the src when the modal is not open
	//  }
 //   }, [isOpen]); // This effect depends on the isOpen prop
 // 
 //   if (!isOpen) {
	//  console.log('Modal is closed, not rendering component');
	//  return null;
 //   }
 // 
 //   const handleOutsideClick = (e) => {
	//  if (e.target === e.currentTarget) {
	//    onClose();
	//  }
 //   };
 // 
 //   return (
	//  <div className="scorecard-modal-overlay" onClick={handleOutsideClick}>
	//    <div className="scorecard-modal-content" onClick={(e) => e.stopPropagation()} style={{ width: '80%', height: '80%' }}>
	// 	 <div className="scorecard-modal-header">
	// 	   <h2>Scorecard</h2>
	// 	   <span className="scorecard-modal-close-button" onClick={onClose}>&times;</span>
	// 	 </div>
	// 	 <div className="scorecard-modal-body" style={{ width: '100%', height: '100%' }}>
	// 	   <iframe
	// 		 style={{ width: '95%', height: '100%' }}
	// 		 frameBorder="0"
	// 		 scrolling="no"
	// 		 src={iframeSrc} // The src is set based on the state
	// 		 title="Scorecard"
	// 	   ></iframe>
	// 	 </div>
	//    </div>
	//  </div>
 //   );
 // };




 const FeedbackModal = ({ isOpen, onClose, candidateData, fetchFeedback }) => {
   const [userQuery, setUserQuery] = useState("");
   const userInputRef = useRef(null);
 
   // Function to handle input change in the contentEditable div
   const handleInputChange = () => {
	 setUserQuery(userInputRef.current.textContent);
   };
 
   // Function to handle clicks outside the modal, to close it
   const handleOutsideClick = (e) => {
	 if (e.target === e.currentTarget) {
	   onClose();
	 }
   };
 
   // Function to submit feedback
   const handleFeedbackSubmit = async (feedbackText) => {
	 // Assuming you store your JWT token in localStorage
	 const token = localStorage.getItem('authToken');
	 const candidateName = candidateData.name; // Using candidateData passed as a prop
 
	 try {
	   const response = await fetch('https://phg.pretiumsearch.com/api/submit-feedback', {
		 method: 'POST',
		 headers: {
		   'Content-Type': 'application/json',
		   'Authorization': `Bearer ${token}`,
		 },
		 body: JSON.stringify({
		   feedback: feedbackText,
		   candidateName,
		 }),
	   });
 
	   if (response.ok) {
		 console.log("Feedback submitted successfully");
		 setUserQuery(""); // Reset userQuery after successful submission
		 if (userInputRef.current) {
		   userInputRef.current.textContent = ""; // Clear the contentEditable div
		 }
		 fetchFeedback(); // Invoke fetchFeedback to refresh the feedback list
		 onClose(); // Close the modal
	   } else {
		 console.error("Failed to submit feedback");
	   }
	 } catch (error) {
	   console.error("Error submitting feedback:", error);
	 }
   };
 
   // Ensure the contentEditable div is focused when the modal opens
   useEffect(() => {
	 if (isOpen && userInputRef.current) {
	   userInputRef.current.focus();
	 }
   }, [isOpen]);
 
   if (!isOpen) return null;
 
   return (
	 <div className="feedback-modal-overlay" onClick={handleOutsideClick}>
	   <div className="feedback-modal-content" onClick={(e) => e.stopPropagation()}>
		 <div className="feedback-modal-header">
		   <h2>Submit Feedback</h2>	
		   <span className="feedback-close-button" onClick={onClose}>&times;</span>  
		 </div>
		 <div className="feedback-modal-body">
		   <div
			 contentEditable="true"
			 ref={userInputRef}
			 onInput={handleInputChange}
			 className="feedback-feedback-editable"
			 role="textbox"
			 aria-multiline="true"
			 data-placeholder="Enter feedback here"
		   ></div>
		 </div>
		 <div className="feedback-modal-footer">
		   <button onClick={() => handleFeedbackSubmit(userQuery)}>Submit</button>
		 </div>
	   </div>
	 </div>
   );
 };

  // Inside your CandidateCard component
  const fetchFeedback = async () => {
	  
	setFeedbackEntries([]); 
	setIsLoadingFeedback(true); 
	const response = await fetch(`https://phg.pretiumsearch.com/api/feedback?candidateName=${encodeURIComponent(candidateData.name)}`);
	if (response.ok) {
	  const data = await response.json();
	  setFeedbackEntries(data);
	} else {
	  console.error("Failed to fetch feedback");
	  // Handle error appropriately
	}
	setIsLoadingFeedback(false);
  };
  
  useEffect(() => {
	fetchFeedback();
  }, [candidateData.name]); 



  const formattedNotes = candidateData.notes ? formatRichText(candidateData.notes) : 'No notes available.';
  const formattedCompensation = candidateData.compensation ? formatRichText(candidateData.compensation) : 'Compensation data not available.';
  return (
	<div className="candidate-card">
	<div className="candidate-card-header">
		<div className="candidate-image-container">
			{profileImage && <img src={profileImage} alt={`${name} Profile`} className="candidate-profile-image" />}			
		</div>
		<div className="candidate-name-details">
			<h3 className="candidate-name">
			  {name} 
			  <span className="candidate-status">{renderMultiSelect(status)}</span>
			</h3>
			<p className="candidate-position-company">{currentPosition} - {currentCompany}</p>
			<p className="candidate-location">{location}</p>
			<div className="candidate-tags">
			  {renderMultiSelect(tags)}
			</div>
		</div>
		<div className="contact-container">
			<div className="contact-icons">
				<img src={linkedinIcon} alt="LinkedIn Icon" className="icon" />
				<a href={url} target="_blank" rel="noopener noreferrer">LinkedIn</a>
			</div>
			<div className="contact-icons">
				<img src={emailIcon} alt="Email Icon" className="icon" />
				<span>{email}</span>
			</div>
			<div className="contact-icons">
				<img src={phoneIcon} alt="Phone Icon" className="icon" />
				<span>{candidateData.phone}</span>
			</div>
			<div className="contact-icons">
			  <img
				src={scorecardIcon}
				alt="Scorecard Icon"
				className="icon" // Keep the class for consistency
				onClick={() => setIsScorecardModalOpen(true)}
			  />
			  <span className="scorecard-text" onClick={() => setIsScorecardModalOpen(true)}>
				  Scorecard
				</span> 
			</div>
			<div className="contact-icons">
				<span style={{ fontWeight: 'bold' }}>Candidate Score:</span> {candidateData.score}
			</div>
		</div>
	</div>
	   <div className="candidate-details-wrapper">
	   <div className="details-group">
		 {/* Compensation Section */}
		 <div className="detail-item">
		   <div className="detail-title">
			 <strong><img src={compensationLogo} alt="Compensation Logo" className="title-icon" /> Partnership Model in Place:</strong>
		   </div>
		   <div className="detail-scrollable-content">
			 <p>{formattedCompensation}</p>
		   </div>
		 </div>
		 {/* Education Section */}
		 <div className="detail-item">
		   <div className="detail-title">
			 <strong><img src={educationLogo} alt="Education Logo" className="title-icon" /> Education:</strong>
		   </div>
		   <div className="detail-scrollable-content">
			 <ul>
				{education1 && <li>{education1}</li>}
				{education2 && <li>{education2}</li>}
				{education3 && <li>{education3}</li>}
			  </ul>
		   </div>
		 </div>
		 {/* Languages Section */}
		 <div className="detail-item">
		   <div className="detail-title">
			 <strong><img src={languagesLogo} alt="Languages Logo" className="title-icon" /> Languages:</strong>
		   </div>
		   <div className="detail-scrollable-content">
			 <ul>
			   {language1 && <li>{language1}</li>}
			   {language2 && <li>{language2}</li>}
			   {language3 && <li>{language3}</li>}
			 </ul>
		   </div>
		 </div>
		 <div className="documents-group">
			<div className="documents-title">
			  <strong><img src={documentsLogo} alt="Documents Logo" className="documents-icon" />Documents:</strong>
			</div>
			<div className="documents-scrollable-content">
			  {files && files.length > 0 ? (
				<ul>
				  {files.map((file, index) => (
					<li key={index}>
					  <a href={file.url} target="_blank" rel="noopener noreferrer">{file.name}</a>
					</li>
				  ))}
				</ul>
			  ) : (
				<p>No documents available.</p>
			  )}
			</div>
		  </div>
	   </div>
	   <div className="notes-and-documents">
		 {/* Notes Section */}
		 <div className="notes-group">
		   <div className="notes-title">
			 <strong><img src={notesLogo} alt="Notes Logo" className="notes-icon" /> Notes:</strong>
		   </div>
		   <div className="notes-scrollable-content">
			 {formattedNotes}
		   </div>
		 </div>
		 <div className="feedback-group">
		   <div className="feedback-title">
			 <strong><img src={feedbackLogo} alt="Feedback Logo" className="feedback-icon" /> Feedback:</strong>
			 <img
				 src={addfeedbackLogo}
				 alt="Add Feedback"
				 className="add-feedback-icon"
				 onClick={() => setIsModalOpen(true)}
			   />
		   </div>
		   <div className="feedback-scrollable-content">
			 {isLoadingFeedback ? (
			   // Display this when feedback is being fetched
			   <div className="spinner"></div>
			 ) : feedbackEntries.length > 0 ? (
			   // Display this if feedback fetching is complete and entries are present
			   feedbackEntries.map((entry, index) => (
				 <div key={index} className="feedback-entry">
				   <div className="feedback-entry-header">
					 {entry.profilePicture && (
					   <img src={entry.profilePicture} alt={`${entry.fullName}'s profile`} className="feedback-profile-pic" />
					 )}
					 <strong>{entry.fullName}</strong>
				   </div>
				   <p>{entry.feedback}</p>
				   <p className="feedback-date">{new Date(entry.dateSubmitted).toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' })}</p>
				 </div>
			   ))
			 ) : (
			   // Display this if feedback fetching is complete and no entries are present
			   <p>No feedback available.</p>
			 )}
		   </div>
		 </div>
	   </div>
	   <FeedbackModal
		 isOpen={isModalOpen}
		 onClose={() => setIsModalOpen(false)}
		 candidateData={candidateData} // Pass candidateData if used in the submission
		 fetchFeedback={fetchFeedback} // Pass fetchFeedback if you need to refresh the list after submission
	   />
	   <ScorecardModal
		   isOpen={isScorecardModalOpen}
		   onClose={() => setIsScorecardModalOpen(false)}
		/>
		  <div className="chat-container">
			  <AnalyzeCandidateData candidateData={candidateData} />
		  </div>
	  </div>
	</div>
  );
};

export default CandidateCard;
