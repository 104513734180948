import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Cell, ResponsiveContainer } from 'recharts';

const CompensationBarChart = ({ data }) => {
	 const chartData = [
		 { name: 'Min', value: data.minCompensation },
		 { name: 'Median', value: data.medianCompensation },
		 { name: 'Max', value: data.maxCompensation }
	 ];
 
	 return (
		  <div className="chart-container">
			<h2 style={{ textAlign: 'center', fontSize: 16, fontWeight: 'bold' }}>Compensation (USD p.a.g.)</h2>
			<ResponsiveContainer width="100%" height={150}>
			  <BarChart data={chartData} margin={{ top: 20, right: 30, left: -20, bottom: 30 }}>
				<XAxis dataKey="name" style={{ fontSize: 10 }} angle={-45} textAnchor="end" />
				<YAxis style={{ fontSize: 10 }} />
				<Tooltip />
				<Bar dataKey="value" fill="#82ca9d" />
			  </BarChart>
			</ResponsiveContainer>
		  </div>
		);
 };


 export default CompensationBarChart;