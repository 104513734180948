// utils.js
export function calculateMedian(numbers) {
  const sortedNumbers = [...numbers].sort((a, b) => a - b);
  const midIndex = Math.floor(sortedNumbers.length / 2);
  if (sortedNumbers.length % 2 === 0) {
	return (sortedNumbers[midIndex - 1] + sortedNumbers[midIndex]) / 2;
  }
  return sortedNumbers[midIndex];
}

export const processDataForSummary = (candidates) => {
  const locationCounts = candidates.reduce((acc, candidate) => {
	acc[candidate.location] = (acc[candidate.location] || 0) + 1;
	return acc;
  }, {});

  const statusCounts = candidates.reduce((acc, candidate) => {
	candidate.status.forEach(status => {
	  const statusName = status.name;
	  acc[statusName] = (acc[statusName] || 0) + 1;
	});
	return acc;
  }, {});

  const tagCounts = candidates.reduce((acc, candidate) => {
	candidate.tags.forEach(tag => {
	  const tagName = tag.name;
	  acc[tagName] = (acc[tagName] || 0) + 1;
	});
	return acc;
  }, {});

  const compensationNumbers = candidates
	.map(candidate => parseFloat(candidate.compensationNum))
	.filter(num => !isNaN(num));

  const minCompensation = Math.min(...compensationNumbers);
  const maxCompensation = Math.max(...compensationNumbers);
  const medianCompensation = calculateMedian(compensationNumbers);

  return { locationCounts, statusCounts, tagCounts, compensationStats: { minCompensation, maxCompensation, medianCompensation } };
};
