import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Cell, ResponsiveContainer } from 'recharts';

const StatusBarChart = ({ data }) => {
	 const chartData = Object.entries(data).map(([name, count]) => ({ name, count }));
 
	 const barColors = ['#8dd1e1', '#a4de6c', '#d0ed57', '#ffc658', '#8884d8'];
 
	 return (
		  <div className="chart-container">
			<h2 style={{ textAlign: 'center', fontSize: 16, fontWeight: 'bold' }}>Status</h2>
			<ResponsiveContainer width="100%" height={150}>
			  <BarChart data={chartData} margin={{ top: 20, right: 30, left: -20, bottom: 30 }}>
				<XAxis 
				  dataKey="name" 
				  tick={({ x, y, payload }) => (
					<g transform={`translate(${x},${y})`}>
					  <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)" style={{ fontSize: '10px' }}>
						{payload.value.length > 10 ? `${payload.value.substring(0, 10)}...` : payload.value}
					  </text>
					</g>
				  )}
				  interval={0} // Ensure every label is shown
				  style={{ fontSize: '10px' }}
				/>
				<YAxis tickFormatter={value => Number.isInteger(value) ? value : ''} style={{ fontSize: 10 }} />
				<Tooltip />
				<Bar dataKey="count">
				  {chartData.map((entry, index) => (
					<Cell key={`cell-${index}`} fill={barColors[index % barColors.length]} />
				  ))}
				</Bar>
			  </BarChart>
			</ResponsiveContainer>
		  </div>
		);
 };
 
 export default StatusBarChart;