import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Cell } from 'recharts';

const CompensationBarChart = ({ data }) => {
	 const chartData = [
		 { name: 'Min', value: data.minCompensation },
		 { name: 'Median', value: data.medianCompensation },
		 { name: 'Max', value: data.maxCompensation }
	 ];
 
	 return (
		 <div className="chart-container">
			 <h2 style={{ textAlign: 'center', fontSize: 16, fontWeight: 'bold' }}>Compensation</h2>
			 <BarChart width={280} height={150} data={chartData} margin={{ top: 20, right: 30, left: -20, bottom: 30 }}>
				 <XAxis dataKey="name" style={{ fontSize: 10 }} angle={-45} textAnchor="end" />
				 <YAxis style={{ fontSize: 10 }} />
				 <Tooltip />
				 <Bar dataKey="value" fill="#82ca9d" />
			 </BarChart>
		 </div>
	 );
 };


 export default CompensationBarChart;